<script>
import {
  HUBSPOT_FORM_URL,
  HUBSPOT_KBPAGE_URL,
  INTEGRATION_CUSTOM_PARAMETERS,
} from '@/config/paragon-integration';
import automationsApiService from '@/services/automations-api-service.js';
import { useAutomationsStore } from '@/stores/automations-store.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import HrbrCatalisIntegration from './HrbrCatalisIntegration.vue';
import HrbrParagonIntegration from '@components/Paragon/HrbrParagonIntegration.vue';

export default {
  name: 'HrbrParagonIntegrations',
  props: {
    agreementLinkId: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      automationsStore: useAutomationsStore(),
      harbourStore: useHarbourStore(),
    };
  },
  data() {
    return {
      // Marketplace data
      searchValue: '',

      // State variables
      isLoadingMarketplace: true,

      // Constants
      knowledgeBaseUrl: HUBSPOT_KBPAGE_URL,

      columnCount: 3,
      observer: null,
      linkIntegrations: [],
    };
  },
  computed: {
    filteredIntegrations() {
      let integrations = [...this.automationsStore.paragonIntegrations];
      if (this.searchValue) {
        integrations = integrations?.filter(
          (item) => item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0,
        );
      }

      return integrations?.length ? integrations.map(integration => {
          const type = integration.type === 'custom' ? integration.name.toLowerCase() : integration.type;
          const enabledIntegration = this.linkIntegrations.find(item => item.integration_type === type);
          return {
            ...integration,
            ...(enabledIntegration && { status: 'enabled' }),
          }
        }) : [];
    },
  },
  watch: {
    agreementLinkId: {
      async handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.isLoadingMarketplace = true;
          this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
            this.agreementLinkId,
          );
          this.isLoadingMarketplace = false;
        }
      },
    },
  },
  async created() {
    const authenticated = await this.automationsStore.authenticateInParagon();
    if (!authenticated) {
      return;
    }

    await this.automationsStore.listParagonIntegrations();
    if (this.agreementLinkId) {
      this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
        this.agreementLinkId,
      );
    }

    this.isLoadingMarketplace = false;
  },
  mounted() {
    if (this.$refs.integrationsList) {
      this.observer = new ResizeObserver(this.getGridColumnsCount);
      this.observer.observe(this.$refs.integrationsList);
    }
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    async selectIntegrationParameters(integration) {
      const customIntegrationModals = {
        'custom.catalis': HrbrCatalisIntegration,
      };

      let harbourParameters = {};

      if (this.linkIntegrations?.length > 0) {
        harbourParameters = {
          ...this.linkIntegrations.find(
            (p) => p.integration_type === integration.type,
          ),
        };
      }

      if (integration.type.startsWith('custom')) {
        const linkAutomation = this.linkIntegrations.find(
          (p) => p.integration_type === integration.type,
        );

        this.$buefy.modal.open({
          parent: this,
          component: customIntegrationModals[integration.type],
          hasModalCard: true,
          props: {
            integrationMetadata: this.automationsStore.paragonMetadata[integration.type],
            integrationData: integration,
            linkAutomation
          },
          events: {
            'integration-parameters-selected': async (eventParams) => {
              await this.automationsStore.onCustomIntegrationSave(
                integration.type,
                harbourParameters,
                eventParams,
                async (params) => await this.saveUpdateIntegrationParams(params, integration.type)
              );
            },
          },
        });
        return;
      }

      const props = INTEGRATION_CUSTOM_PARAMETERS[integration.type].getProps(
        {
          integrationMetadata: this.automationsStore.paragonMetadata[integration.type],
          integrationName: integration.type,
          agreementLinkId: this.agreementLinkId,
        },
        harbourParameters,
      );

      this.$buefy.modal.open({
        parent: this,
        component: HrbrParagonIntegration,
        hasModalCard: true,
        props,
        events: {
          'integration-parameters-selected': async (eventParams) => {
            await this.automationsStore.onParagonIntegrationSave(
              integration.type,
              harbourParameters,
              eventParams,
              async (params) => await this.saveUpdateIntegrationParams(params, integration.type)
            );
          },
        },
      });
    },

    async saveUpdateIntegrationParams(harbourParameters, integrationType) {
      // Emit event to parent component when agreement link id is not available
      if (!this.agreementLinkId) {
        const integrationIndex = this.linkIntegrations?.findIndex(
          (p) => p.integration_type === integrationType,
        );
        // Update integration if already exists
        if (integrationIndex > -1) {
          this.linkIntegrations.splice(integrationIndex, 1);
        }
        this.linkIntegrations.push(harbourParameters);

        this.$emit('paragon-integration-parameters-updated', this.linkIntegrations);
        this.automationsStore.isLoadingIntegrations = false;
        return;
      }
      // Update integration parameters
      try {
        await this.automationsStore.updateIntegrationParams(harbourParameters, this.agreementLinkId);
        this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
          this.agreementLinkId,
        );
        this.$emit('update-dashboard-view');
      } catch (e) {
        this.$buefy.toast.open({
          duration: 2500,
          message: 'Error creating/updating Paragon Integration',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    onIntegrationDeleteClicked(integrationType) {
      this.$buefy.dialog.confirm({
        title: 'Disconnect Integration',
        message: 'Are you sure you want to disconnect this integration?',
        confirmText: 'Confirm',
        type: 'is-info',
        iconPack: 'fas',
        hasIcon: true,
        icon: 'circle-exclamation',
        container: '.disconnect-dialog',
        onConfirm: async () => {
          this.automationsStore.isLoadingIntegrations = true;
          if (this.agreementLinkId) {
            const automationId = this.linkIntegrations.find(
              (p) => p.integration_type === integrationType,
            ).automation_id;
            await automationsApiService.deleteHarbourIntegration(automationId);
            this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
              this.agreementLinkId,
            );
          } else {
            const integrationIndex = this.linkIntegrations.findIndex(
              (p) => p.integration_type === integrationType,
            );
            this.linkIntegrations.splice(integrationIndex, 1);
            this.$emit('paragon-integration-parameters-updated', this.linkIntegrations);
          }
          this.$emit('update-dashboard-view');
          this.automationsStore.isLoadingIntegrations = false;
        },
      });
    },

    async onIntegrationButtonClicked(integration) {
      const shouldOpenCustomModal = await this.automationsStore.installIntegration(integration);

      if (shouldOpenCustomModal) await this.selectIntegrationParameters(integration);
    },

    requestParagonIntegration() {
      window.open(HUBSPOT_FORM_URL, '_blank');
    },

    onImgError(evt) {
      // eslint-disable-next-line no-param-reassign
      evt.target.onerror = null;
      // eslint-disable-next-line no-param-reassign
      evt.target.src = 'static/imgs/placeholder-marketplace-logo.svg';
    },

    getGridColumnsCount() {
      const width = this.$refs.integrationsList?.clientWidth;
      this.columnCount = width ? Math.floor(width / 160) : 3;
    },

    getIntegrationName(integration) {
      if (integration.type === 'custom') {
        const name = this.automationsStore.paragonMetadata[integration.name.toLowerCase()].name;
        return name.split('.')[1];
      } else {
        return this.automationsStore.paragonMetadata[integration.type].name;
      }
    },

    getIntegrationIcon(integration) {
      if (integration.type === 'custom') {
        return this.automationsStore.paragonMetadata[integration.name.toLowerCase()].icon;
      } else {
        return this.automationsStore.paragonMetadata[integration.type].icon;
      }
    },

  }
};
</script>

<template>
  <div class="vue-component-integrations-paragon">
    <!-- Not Loading -->
    <div>
      <!-- Header -->
      <div class="marketplace-header">
        <!-- Search bar -->
        <b-field class="marketplace-header-searchbar">
          <b-autocomplete
            v-model="searchValue"
            placeholder="Search 100+ apps"
            icon-pack="fal"
            icon="magnifying-glass"
            clearable>
            <template #header>
              <a v-if="!filteredIntegrations.length" href="#" target="_blank">
                <span @click="requestParagonIntegration"> Request app... </span>
              </a>
            </template>
          </b-autocomplete>
        </b-field>
      </div>

      <!-- Content -->
      <div class="marketplace-content" ref="integrationsList">
        <div class="bottom-fadeout" v-if="isLoadingMarketplace">
          <div
          v-for="index in 6"
          :key="'market-item-placeholder-' + index"
          style="background-color: #f7f7f7">
            <b-skeleton width="100px" height="100px" />
          </div>
        </div>

        <!-- Integrations -->
        <div class="bottom-fadeout" v-else>
          <div
            v-for="(integration, index) in filteredIntegrations"
            :data-name="getIntegrationName(integration)"
            :key="integration.type + '-' + index">
            <!-- Marketplace app -->
            <div class="marketplace-content-app" :class="integration.status">
              <b-dropdown
                v-if="integration.status === 'enabled' && integration.type != 'salesforce'"
                :triggers="['click']"
                aria-role="list"
                :position="index % columnCount === 0 ? 'is-bottom-right' : 'is-bottom-left'"
                :mobile-modal="false">
                <template #trigger>
                  <b-icon pack="fal" icon="ellipsis-vertical"></b-icon>
                </template>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="onIntegrationDeleteClicked(integration.type)">
                  <b-icon pack="fal" icon="trash-can" />
                  Disconnect integration
                </b-dropdown-item>
              </b-dropdown>

              <!-- Marketplace app status tag -->
              <b-icon
                v-if="integration.status === 'enabled'"
                class="marketplace-content-app-tag"
                pack="fas"
                :icon="automationsStore.paragonIntegrationsStatus[integration.status].icon" />

              <b-tag
                v-else-if="!['active', 'enabled'].includes(integration.status)"
                class="marketplace-content-app-tag"
                :class="integration.status">
                {{ automationsStore.paragonIntegrationsStatus[integration.status].label }}
              </b-tag>

              <!-- Marketplace app icon -->
              <div
                :class="['marketplace-content-app-icon', integration.status]"
                @click="
                  integration.status !== 'inactive'
                    ? onIntegrationButtonClicked(integration)
                    : requestParagonIntegration()
                ">
                <!-- Marketplace app icon content -->
                <div class="marketplace-content-app-icon-content">
                  <!-- Marketplace app icon content box -->
                  <div class="marketplace-content-app-icon-content-box">
                    <div class="marketplace-content-app-icon-content-box-img-fallback"></div>
                    <img
                      class="marketplace-content-app-icon-content-box-img"
                      :src="getIntegrationIcon(integration)"
                      @error="onImgError" />
                  </div>
                </div>
              </div>

              <!-- Marketplace app text -->
              <div class="marketplace-content-app-text">
                {{ getIntegrationName(integration) }}
              </div>
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            v-model="automationsStore.isLoadingIntegrations"
            :can-cancel="true"></b-loading>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="marketplace-footer">
      <a :href="knowledgeBaseUrl" target="_blank">Learn more about automations</a>
    </div>

    <div class="disconnect-dialog"></div>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
/* TODO: move to scoped style */
.vue-component-integrations-paragon {
  width: 100%;
  flex-direction: column;
}

.vue-component-integrations-paragon .disconnect-dialog .modal-background {
  position: fixed;
}

.vue-component-integrations-paragon .disconnect-dialog .media {
  align-items: center;
}

.vue-component-integrations-paragon .disconnect-dialog .media-left .icon {
  color: var(--main-primary-color) !important;
}

.vue-component-integrations-paragon .disconnect-dialog .modal-card-foot .button.is-info {
  background-color: var(--main-primary-color);
}

.marketplace-header {
  display: flex;
  margin-bottom: 10px;
}

.marketplace-header-searchbar {
  width: 100%;
}

.marketplace-content {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.bottom-fadeout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-y: auto;
  height: 220px;
  width: auto;
  padding: 15px 20px;
  gap: 25px 55px;

  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black var(--top-mask-size, 0),
    black calc(100% - var(--bottom-mask-size, 0)),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black var(--top-mask-size, 0),
    black calc(100% - var(--bottom-mask-size, 0)),
    transparent 100%
  );
  --bottom-mask-size: 20px;
}

.marketplace-content-app {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marketplace-content-app:hover .marketplace-content-app-icon-content-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
}

.marketplace-content-app:hover .marketplace-content-app-text {
  color: #2d71ad;
}

.marketplace-content-app.enabled:hover .marketplace-content-app-actions {
  visibility: visible;
  opacity: 1;
}

.marketplace-content-app-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 105px;
  min-width: 105px;
  border-radius: 8px;
  background-color: #f7f7f7;
}

.marketplace-content-app .dropdown {
  position: absolute;
  right: -15px;
  top: 45px;
}

.marketplace-content-app .dropdown-trigger .icon {
  width: 10px;
  cursor: pointer;
}

.marketplace-content-app .dropdown-item .icon {
  font-size: 15px;
  color: #acacac;
}

.tag.marketplace-content-app-tag {
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 9px;
  font-size: 10px;
  z-index: 1;
}

.icon.marketplace-content-app-tag {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 25px;
  color: #48c78e;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}

.marketplace-content-app-tag.new {
  background-color: #48c78e;
}

.marketplace-content-app-tag.inactive {
  background-color: #403f3e;
}

.marketplace-content-app-tag.enabled {
  background-color: #48c78e;
}

.marketplace-content-app-icon.enabled {
  position: relative;
  background-color: #48c78e4d;
  border: 1px solid #48c78e;
  border-radius: 8px;
}

.marketplace-content-app-icon-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.marketplace-content-app-icon-content-text {
  font-size: 12px;
  color: #48c78e;
  font-weight: 700;
  cursor: pointer;
}

.marketplace-content-app-icon-content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 1px solid #e8e7e4;
  border-radius: 8px;
  cursor: pointer;
}

.marketplace-content-app-icon-content-box-img {
  width: 40px;
  height: auto;
}

.marketplace-content-app-icon-content-box-img-fallback {
  background-image: url('../../assets/icons/harbour-logo-blue.svg');
  display: none;
}

.marketplace-content-app-text {
  display: flex;
  justify-content: center;
  max-width: 105px;
  font-size: 15px;
  font-weight: 450;
  text-align: center;
  overflow: visible;
  text-transform: capitalize;
}

.marketplace-footer {
  display: flex;
  flex-direction: column;
}

.marketplace-content-app-actions {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in;
}

.marketplace-content-app-actions-item {
  cursor: pointer;
  font-size: 12px;
  color: #444;
}
</style>
