<script>
import {
  INTEGRATION_CUSTOM_PARAMETERS,
  PAYLOAD_OPTIONS,
  TRIGGER_OPTIONS,
} from '@/config/paragon-integration';
import googleSheetsApiService from '@/services/external_services/google_sheets/google-sheets-api-service.js';
import { useAutomationsStore } from '@/stores/automations-store.js';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
  name: 'HrbrParagonIntegration',
  props: {
    integrationMetadata: {
      type: Object,
      default: () => {},
    },
    integrationName: {
      type: String,
    },
    activeTrigger: {
      type: String,
      default: 'completed',
    },
    initialData: {
      type: Object,
      default: () => {},
    },
    agreementLinkId: {
      type: String,
    },
  },
  setup() {
    return {
      harbourStore: useHarbourStore(),
      automationsStore: useAutomationsStore(),
    };
  },
  data() {
    // Prepare dynamic integration data
    const controlsData = INTEGRATION_CUSTOM_PARAMETERS[this.integrationName].controls
      .map((control) => ({
        [control.selectedItemComponentKey]: '',
        [control.dataComponentKey]: [],
      }))
      .reduce((o, item) => ({ ...o, ...item }), []);

    return {
      payloadOptions: PAYLOAD_OPTIONS,
      triggerOptions: TRIGGER_OPTIONS,
      selectedTrigger: null,
      selectedEvent: 'sample',
      selectedItemName: '',
      isPayloadSampleOpen: false,
      triggerSelectionOpened: true,
      settingsOpened: false,
      triggerConfigured: false,
      isLoading: false,
      tableDataLoading: false,
      isFileExplorerLimited: INTEGRATION_CUSTOM_PARAMETERS[this.integrationName].isLimited,
      navigationProvider: INTEGRATION_CUSTOM_PARAMETERS[this.integrationName].navigationProvider,
      navigationRequests: INTEGRATION_CUSTOM_PARAMETERS[this.integrationName].navigationUrls,
      controlsData,
      createdIntegrations: [],
    };
  },
  computed: {
    controls() {
      return INTEGRATION_CUSTOM_PARAMETERS[this.integrationName].controls;
    },
    modalId() {
      return `hrbr-select-integration-options-${this.integrationName}`;
    },
    payloadToDisplay() {
      return Object.entries(this.payloadOptions[this.selectedTrigger?.id] || {});
    },
    isAllParametersSelected() {
      const emptyValues = this.controls.filter((control) => this.controlsData[control.selectedItemComponentKey] == null);
      return emptyValues.length === 0
    }
  },
  watch: {
    triggerSelectionOpened(val) {
      if (val) {
        this.settingsOpened = false;
      }
    },
  },
  async created() {
    this.isLoading = true;

    try {
      let triggerOption = this.triggerOptions.find(
        (item) => !item.disabled.includes(this.integrationName),
      );
      if (this.activeTrigger) {
        triggerOption = this.triggerOptions.find((option) => option.id === this.activeTrigger);
      }
      this.selectedTrigger = triggerOption;

      this.createdIntegrations = await this.automationsStore.listHarbourIntegrations(
        null,
        this.integrationName,
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const control of this.controls) {
        const dataKey = control.dataComponentKey;
        const selectedKey = control.selectedItemComponentKey;
        const initialSelectionKey = control.initialItemPropKey;
        const initialSelectionName = control.initialItemPropName;
        const selectedResponseKey = control.selectedResponseKey;
        this.controlsData[selectedKey] = this.initialData[initialSelectionKey];
        // eslint-disable-next-line no-await-in-loop
        this.controlsData[dataKey] = [{
          id: this.initialData[initialSelectionKey],
          name: this.initialData[initialSelectionName],
        }]

        if (!this.controlsData[selectedKey] && this.controlsData[dataKey].length) {
          const createdIntegrationsByType = this.createdIntegrations.filter(
            (item) => item.integration_type === this.integrationName,
          );
          const firstCreatedIntegrationSelection =
            createdIntegrationsByType[0]?.custom_parameters[selectedResponseKey];
          const isSelectedItemInList = this.controlsData[dataKey].find(
            (item) => item.id === firstCreatedIntegrationSelection,
          );

          let selectedValue;
          if (createdIntegrationsByType.length && isSelectedItemInList) {
            selectedValue = isSelectedItemInList.id;
          } else {
            selectedValue = this.controlsData[dataKey][0].id;
          }
          this.controlsData = {
            ...this.controlsData,
            [selectedKey]: selectedValue,
          }
        }

        // Move selected item on top (for limited file explorer)
        const selectedItem = this.controlsData[dataKey].find(
          (item) => item.id === this.controlsData[selectedKey],
        );
        if (selectedItem) {
          this.controlsData[dataKey] = [
            selectedItem,
            ...this.controlsData[dataKey].filter(
              (item) => item.id !== this.controlsData[selectedKey],
            ),
          ];
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    getDataItemKey(controlIndex, key) {
      return this.controls[controlIndex][key];
    },
    getControlComponentData(controlIndex, key) {
      return this.controlsData[this.getDataItemKey(controlIndex, key)];
    },
    getSelectedNavigationItemName(controlIndex) {
      const dataKey = this.getDataItemKey(controlIndex, 'dataComponentKey');
      const selectedKey = this.getDataItemKey(controlIndex, 'selectedItemComponentKey');
      return (
        this.controlsData[dataKey].find((item) => item.id === this.controlsData[selectedKey])
          ?.name || this.selectedItemName
      );
    },
    onContinueClicked() {
      this.triggerSelectionOpened = false;
      this.settingsOpened = true;
      this.triggerConfigured = true;
    },
    async onNavigationItemSelected(item, controlIndex, control) {
      this.isLoading = true;
      this.selectedItemName = item.name;
      const dataKey = this.getDataItemKey(controlIndex, 'selectedItemComponentKey');
      const dataComponentKey = control.dataComponentKey;
      this.controlsData[dataComponentKey] = [item]
      this.controlsData = {
        ...this.controlsData,
        [dataKey]: item.id,
      }
      if (control.updateNextOnSelection) {
        const nextDataKey = this.getDataItemKey(controlIndex + 1, 'dataComponentKey');
        const selectedKey = this.getDataItemKey(controlIndex + 1, 'selectedItemComponentKey');
        const payload = this.getDataItemKey(controlIndex + 1, 'payload');
        const dataResponseKey = this.getDataItemKey(controlIndex + 1, 'dataResponseKey');
        const mapType = this.getDataItemKey(controlIndex + 1, 'mapType');
        this.controlsData[nextDataKey] = await this.getControlData(
          payload(item.id),
          dataResponseKey,
          mapType,
        );
        // eslint-disable-next-line prefer-destructuring
        this.controlsData = {
          ...this.controlsData,
          [selectedKey]: this.controlsData[nextDataKey][0].id,
        }
      }
      this.isLoading = false;
    },
    async getControlData(payload, dataResponseKey = null, mapType) {
      if (payload.externalApi) {
        const spreadsheeId = payload.parameters[0].source.value;
        const data = await googleSheetsApiService.getSpreadsheet(spreadsheeId, {
          user_id: this.harbourStore.contextDict?.systemuserid,
        })
        if (!data) {
          return []
        }

        return data.sheets.map((sheet) => ({
          id: sheet.properties.sheet_id,
          name: sheet.properties.title
        }))
      }

      const data = await this.automationsStore.paragonActions(payload)
      if (!data) {
        return []
      }

      if (mapType === 'flatArray') {
        return data.output.map((item) => item.value);
      }

      return dataResponseKey
        ? data.output[dataResponseKey].map((item) => ({
            id: item.value,
            name: item.label,
          }))
        : Object.values(data.output).map(item => ({
            id: item.id != null ? item.id : item.value,
            name: item.name != null ? item.name : item.label,
          }));
    },
    save() {
      const selectedItems = this.controls.reduce(
        (acc, control) => ({
          ...acc,
          [control.selectedItemKey]: this.controlsData[control.selectedItemComponentKey],
          [control.selectedItemName]: this.controlsData[control.dataComponentKey].find(item => item.id === this.controlsData[control.selectedItemComponentKey]).name,
        }),
        {},
      );
      this.$emit('integration-parameters-selected', {
        integrationName: this.integrationName,
        selectedTrigger: this.selectedTrigger.id,
        ...selectedItems,
      });
      this.$parent.close();
    },
    async updateTableData(control) {
      this.tableDataLoading = true;
      const dataKey = control.dataComponentKey;
      this.controlsData[dataKey] = await this.getControlData(
        control.payload,
        control.dataResponseKey,
        control.mapType,
      );
      this.tableDataLoading = false;
    },

    async onFileSelected(files, controlIndex, control) {
      const selectedItem = {
        id: files.docs[0].id,
        name: files.docs[0].name,
      }
      this.onNavigationItemSelected(selectedItem, controlIndex, control)
    },

    async selectFile(controlIndex, control) {
      this.automationsStore.runFilePicker(this.integrationName, (files) => this.onFileSelected(files, controlIndex, control));
    },

    async onControlParameterSelected(value, control) {
      this.controlsData = {
        ...this.controlsData,
        [control.selectedItemComponentKey]: value,
      }
    }
  },
};
</script>

<template>
  <div
    :id="modalId"
    :class="['hrbr-select-integration-options', 'modal-card', {'file-picker-opened': automationsStore.filePickerOpened}]"
    style="width: 100%"
  >
    <section class="modal-card-body">
      <b-collapse
        class="card integration-settings-card"
        animation="slide"
        v-model="triggerSelectionOpened">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <div class="card-header-title">
              <div class="card-header-image">
                <img src="/static/imgs/harbour-logo-blue.svg" alt="harbour logo" />
              </div>
              <div class="card-header-text">
                <p class="name">Harbour</p>
                <p>Select trigger</p>
              </div>
            </div>

            <a class="card-header-icon">
              <b-icon
                :pack="triggerConfigured ? 'fas' : 'fal'"
                :class="triggerConfigured && 'checked'"
                :icon="
                  triggerConfigured
                    ? 'circle-check'
                    : props.open
                    ? 'circle-chevron-down'
                    : 'circle-chevron-up'
                ">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <b-field label="Select trigger">
            <b-select v-model="selectedTrigger">
              <option
                v-for="option in triggerOptions"
                v-if="!option.disabled.includes(integrationName)"
                :value="option"
                :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <b-collapse class="payload-section" animation="slide" v-model="isPayloadSampleOpen">
            <template #trigger="props">
              <div class="payload-section-label" role="button">
                <p style="font-weight: 600">Sample payload</p>
                <a class="card-header-icon">
                  <b-icon size="is-small" pack="fal" :icon="props.open ? 'caret-down' : 'caret-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div v-if="payloadToDisplay.length" class="payload-example">
              <div v-for="item in payloadToDisplay" :key="item[0]">
                <div v-if="Array.isArray(item[1])" class="payload-example-group">
                  <b>{{ item[0] }}: [</b>
                  <div
                    v-for="(nestedItem, index) in Object.entries(item[1])"
                    :key="nestedItem[0]"
                    class="payload-example-item-nested">
                    <p>{</p>
                    <p
                      v-for="prop in Object.entries(nestedItem[1])"
                      :key="prop[1]"
                      class="payload-example-item">
                      <b>{{ prop[0] }}: </b>
                      <span>"{{ prop[1] }}"</span>
                    </p>
                    <p>
                      }
                      <span v-if="index < item[1].length - 1">,</span>
                    </p>
                  </div>
                  <b>]</b>
                </div>
                <p v-else class="payload-example-item">
                  <b>{{ item[0] }}: </b>
                  <span>"{{ item[1] }}"</span>
                </p>
              </div>
            </div>
          </b-collapse>

          <b-button type="is-success" label="Continue" @click="onContinueClicked" />
        </div>
      </b-collapse>

      <b-collapse class="card integration-settings-card" animation="slide" v-model="settingsOpened">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <div class="card-header-title">
              <div class="card-header-image">
                <img :src="integrationMetadata.icon" alt="logo" />
              </div>
              <div class="card-header-text">
                <p class="name">{{ integrationMetadata.name }}</p>
                <p>{{ selectedTrigger && selectedTrigger.name }}</p>
              </div>
            </div>

            <a class="card-header-icon">
              <b-icon pack="fal" :icon="props.open ? 'circle-chevron-down' : 'circle-chevron-up'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <b-field
            v-for="(control, index) in controls"
            :key="control.selectedItemComponentKey"
            :label="
              control.navigationComponent
                ? control.label(getSelectedNavigationItemName(index))
                : control.label
            ">
            <b-button
              v-if="control.navigationComponent"
              type="is-success"
              outlined expanded
              @click="() => selectFile(index, control)">
              Open File Picker
            </b-button>
            <b-select
              v-else-if="controlsData[controls[index].selectedItemComponentKey] != null"
              v-model="controlsData[controls[index].selectedItemComponentKey]"
              :loading="isLoading"
              :disabled="isLoading"
              :placeholder="control.placeholder"
              @input="(event) => onControlParameterSelected(event, controls[index])">
              <option
                v-for="option in getControlComponentData(index, 'dataComponentKey')"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-button
            type="is-success"
            label="Save"
            :disabled="!isAllParametersSelected"
            @click="save" />
        </div>
      </b-collapse>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-select-integration-options {
  &.file-picker-opened {
    & + :deep(.modal-close) {
      display: none !important;
    }
  }
}
</style>

<style>
/* TODO: move to scoped style */

.hrbr-select-integration-options .modal-card-body {
  display: flex;
  flex-direction: column;
  width: 600px !important;
}

.hrbr-select-integration-options .content-right-aligned {
  text-align: right;
}

.integration-settings-card {
  width: 100%;
}

.integration-settings-card + .integration-settings-card {
  margin-top: 35px;
}

.integration-settings-card .card-header-text {
  margin-left: 15px;
}

.integration-settings-card .card-header-text .name {
  color: #b7b7b7;
}

.integration-settings-card .card-header {
  box-shadow: none;
}

.integration-settings-card .card-content {
  padding-top: 10px;
}

.integration-settings-card .card-header-icon i {
  font-size: 1.6rem;
  color: #dbdbdb;
}

.integration-settings-card .card-header-icon .icon.checked i {
  color: #48c78e;
}

.integration-settings-card .card-header-image {
  display: flex;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #b5b5b570;
}

.integration-settings-card .card-header-image img {
  width: 40px;
  height: 40px;
}

.integration-settings-card select,
.integration-settings-card .select {
  width: 100%;
  height: auto;
}

.integration-settings-card .payload-example {
  max-height: 300px;
  padding: 20px;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  overflow: auto;
  border: 1px solid #b5b5b570;
  border-radius: 10px;
  width: 100%;
}

.integration-settings-card .payload-example span {
  word-break: break-all;
}

.integration-settings-card .payload-example-item-nested,
.integration-settings-card .payload-example-item-nested .payload-example-item {
  padding-left: 15px;
}

.integration-settings-card .payload-section {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding: 10px 0;
}

.integration-settings-card .payload-section-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.integration-settings-card .card-header-icon i.fa-caret-up {
  font-size: 1.2rem;
  color: #000;
}

.integration-settings-card .card-header-icon i.fa-caret-down {
  font-size: 1.2rem;
  color: #000;
}
</style>
