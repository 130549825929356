import { reactive, ref } from 'vue';

function useValidators() {
  const isEmpty = (fieldName, fieldValue, fieldLabel) => {
    return !fieldValue ? 'The ' + fieldLabel + ' field is required' : '';
  };

  const minLength = (fieldName, fieldValue, fieldLabel, min) => {
    return fieldValue.length < min
      ? `The ${fieldLabel} field must be at least ${min} characters long`
      : '';
  };

  const maxLength = (fieldName, fieldValue, fieldLabel, max) => {
    return fieldValue.length > max
      ? `The ${fieldLabel} field must be less then ${max} characters long`
      : '';
  };

  const isEmail = (fieldName, fieldValue, fieldLabel) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(fieldValue) ? 'The input is not a valid ' + fieldLabel + ' address' : '';
  };

  const isNum = (fieldName, fieldValue, fieldLabel) => {
    let isNum = /^\d+$/.test(fieldValue);
    return !isNum ? 'The ' + fieldLabel + ' field can only have numbers' : '';
  };

  const isCurrency = (fieldName, fieldValue, fieldLabel) => {
    let isNum = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i.test(fieldValue);
    return !isNum ? 'The ' + fieldLabel + ' field can not contain letters' : '';
  };

  return { isEmpty, minLength, maxLength, isEmail, isNum, isCurrency };
}

export default function useFormValidation() {
  const { isEmpty, isNum, maxLength, minLength, isEmail, isCurrency } = useValidators();
  const errors = reactive({});
  const hasErrors = ref(false);

  const rulesMap = new Map([
    ['required', isEmpty],
    ['max', maxLength],
    ['min', minLength],
    ['numeric', isNum],
    ['email', isEmail],
    ['currency', isCurrency],
  ]);

  const hasNoErrors = () => {
    if (!Object.keys(errors).length) return true;
    return Object.values(errors).every((array) => !array.length);
  };

  const validate = (fieldName, fieldValue, rules, fieldLabel, length = 0) => {
    errors[fieldName] = [];

    for (const rule of rules) {
      const validationFunction = rulesMap.get(rule);
      const errorString = validationFunction(fieldName, fieldValue, fieldLabel, length);
      if (!errorString) {
        continue;
      }
      errors[fieldName].push(errorString);
    }

    hasErrors.value = !hasNoErrors();
  };

  const clearError = (fieldName) => {
    errors[fieldName] = [];
    hasErrors.value = !hasNoErrors();
  };

  return { errors, validate, hasErrors, clearError };
}
