import Vue from 'vue';

export const endpointUrl = '/api/external-services/paragon/google-sheets';

export default {
  async getSpreadsheet(spreadsheet_id, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .get(`${endpointUrl}/spreadsheets/${spreadsheet_id}`, null, params)
      .catch((err) => {
        throw new Error(err);
      });
    return data;
  },
};
